import React, { createContext, useState, useEffect } from 'react'
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent'

type NavigationProviderProps = {
  children: React.ReactNode
}

type NavigationContextType = {
  isSidenavVisible: boolean
  isPromotionDrawerVisible: boolean
  closeSidenav: () => void
  openSidenav: () => void
  closePromotionDrawer: () => void
  openPromotionDrawer: () => void
  saveBrowserHistory: (history: any) => void
  initialSearchParam: string
  savedBrowserHistory: any
}

const defaultContextValues = {
  isSidenavVisible: false,
  isPromotionDrawerVisible: false,
  closeSidenav: () => null,
  openSidenav: () => null,
  closePromotionDrawer: () => null,
  openPromotionDrawer: () => null,
  saveBrowserHistory: () => null,
  initialSearchParam: '',
  savedBrowserHistory: null,
}

export const NavigationContext =
  createContext<NavigationContextType>(defaultContextValues)

const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const [isSidenavVisible, setIsSidenavVisible] = useState(false)
  const [initialSearchParam, setInitialSearchParam] = useState('')
  const [isPromotionDrawerVisible, setIsPromotionDrawerVisible] =
    useState(false)
  const [savedBrowserHistory, setSavedBrowserHistory] = useState<any>(null)

  useEffect(() => {
    setInitialSearchParam(window.location.search)
  }, [])

  const closeSidenav = () => {
    if (isSidenavVisible) {
      setIsSidenavVisible(false)

      document.body.style.overflow = 'auto'
    }
  }

  const openSidenav = () => {
    if (!isSidenavVisible) {
      setIsSidenavVisible(true)
      document.body.style.overflow = 'hidden'
    }
  }

  const openPromotionDrawer = () => {
    setIsPromotionDrawerVisible(true)

    document.body.style.overflow = 'hidden'
    document.getElementsByTagName('html')[0].style.overflow = 'hidden'

    if (!isPromotionDrawerVisible) {
      emitMatomoEvent({
        event: eventsNames.click_promocja_otwarcie,
      })
    }
  }
  const closePromotionDrawer = (noMatomoEvent?: boolean) => {
    const formOverlayVisible = document.querySelector('.form-overlay-visible')

    if (formOverlayVisible) {
      return
    }

    setIsPromotionDrawerVisible(false)
    document.body.style.overflow = 'auto'
    document.getElementsByTagName('html')[0].style.overflow = 'auto'

    if (noMatomoEvent) {
      return
    }

    if (isPromotionDrawerVisible) {
      emitMatomoEvent({
        event: eventsNames.click_promocja_zamkniecie,
      })
    }
  }

  const saveBrowserHistory = (history: any) => {
    setSavedBrowserHistory(history)
  }

  return (
    <NavigationContext.Provider
      value={{
        isSidenavVisible,
        isPromotionDrawerVisible,
        closeSidenav,
        openSidenav,
        closePromotionDrawer,
        openPromotionDrawer,
        saveBrowserHistory,
        initialSearchParam,
        savedBrowserHistory,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
