import React, { createContext, useState, useEffect, useMemo } from 'react'
import scrollToSection from 'utils/scrollToSection'

export type Video = {
  link: string
  url: string
}

type VideosProviderProps = {
  children: React.ReactNode
}

type VideosContextType = {
  videosURLS: Video[]
  currentVideoLink: string
  modalVisible: boolean
  openModal: () => void
  closeModal: () => void
  changeCurrentVideoLink: (link: string) => void
  saveVideos: (videos: Video[]) => void
}

const defaultContextValues = {
  videosURLS: [],
  currentVideoLink: '',
  modalVisible: false,
  openModal: () => null,
  closeModal: () => null,
  changeCurrentVideoLink: () => null,
  saveVideos: () => null,
}
const VideosContext = createContext<VideosContextType>(defaultContextValues)

export const VideosProvider: React.FC<VideosProviderProps> = ({ children }) => {
  const [videosURLS, setVideosURLS] = useState<Video[]>([])
  const [currentVideoLink, setCurrentVideoLink] = useState('')
  const [modalVisible, setModalVisible] = useState(false)

  const saveVideos = (videos: Video[]) => setVideosURLS(videos)
  const closeModal = () => {
    setModalVisible(false)
    setCurrentVideoLink('')
  }
  const openModal = () => setModalVisible(true)
  const changeCurrentVideoLink = (link: string) => setCurrentVideoLink(link)

  useEffect(() => {
    const href = window.location.href

    videosURLS.some(({ link, url }) => {
      if (href.includes(url)) {
        scrollToSection('#okolica-miejsca')
        setTimeout(() => {
          changeCurrentVideoLink(link)
          openModal()
        }, 1000)

        return true
      }
      return false
    })
  }, [videosURLS])

  const value = useMemo(
    () => ({
      videosURLS,
      currentVideoLink,
      modalVisible,
      openModal,
      closeModal,
      changeCurrentVideoLink,
      saveVideos,
    }),
    [videosURLS, currentVideoLink, modalVisible, saveVideos]
  )

  return (
    <VideosContext.Provider value={value}>{children}</VideosContext.Provider>
  )
}

export default VideosContext
